import Scrollbar from "../../Scrollbar";
import {
  Box,
  Button,
  Drawer,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import Logo from "../../../LogoSign";
import SidebarMenu from "./SidebarMenu";
import SidebarTopSection from "./SidebarTopSection";
import {
  useSidebarShow,
  useSidebarClose,
  useSidebarToggle,
} from "src/contexts/GlobalContext";
import dayjs from "dayjs";
import { ArrowBack, Minimize } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme, isMinimized }) => `
        margin: ${isMinimized ? theme.spacing(2, 3): theme.spacing(2, 2)};
`
);

function Sidebar({ isMinimized }) {
  const [localEnter, setLocalEnter] = useState(false);
  const sidebarShow = useSidebarShow();
  const handleCloseSidebar = useSidebarClose();
  const theme = useTheme();
  const navigate = useNavigate();
  // const [minimized, setMinimized] = useState(false);

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            sm: "inline-block",
            md: "inline-block",
            lg: "inline-block",
          },
          width: !localEnter && !isMinimized ? "60px" : theme.sidebar.width,
          minWidth: !localEnter && !isMinimized ? "60px" : theme.sidebar.width,
          position: "fixed",
          left: 0,
          top: 0,
        }}
      >
        <Scrollbar
          onMouseEnter={() => setLocalEnter(true)}
          onMouseLeave={() => setLocalEnter(false)}
        >
          <TopSection>
          {isMinimized || localEnter ?<Box
              sx={{
                width: "100%",
                mt: 2,
                mb: 3,
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              {isMinimized || localEnter ? <Logo /> : <></>}
              {isMinimized || localEnter ? (
                <Tooltip title={"back"} arrow>
                  <Button onClick={() => navigate(-1)} variant="text">
                    <ArrowBack />
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}
            </Box> : <Logo size={30} />}
            {/* <SidebarTopSection /> */}
          </TopSection>
          <SidebarMenu isMinimized={isMinimized || sidebarShow} localEnter={localEnter} />
        </Scrollbar>
        {isMinimized || localEnter ? <Box
          display={{ xs: "block" }}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: 0,
            background: theme.sidebar.background,
            height: 50,
            padding: 14,
            width: theme.sidebar.width,
          }}
          alignItems="center"
          textAlign={{ xs: "center" }}
        >
          <Box>
            <Typography variant="subtitle0">
              &copy; {dayjs().format("YYYY")} - Park Limited
            </Typography>
          </Box>
        </Box>:<></>}
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
          // background: 'rgb(17, 24, 39)'
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarShow}
        onClose={handleCloseSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box
                sx={{
                  width: 52,
                  ml: 1,
                  mt: 1,
                  mb: 3,
                  display: "inline-flex",
                }}
              >
                <Logo />
                <Tooltip title={"back"} arrow>
                  <Button onClick={() => navigate(-1)} variant="text">
                    <ArrowBack />
                  </Button>
                </Tooltip>
              </Box>
              <SidebarTopSection />
            </TopSection>
            <SidebarMenu isMinimized={isMinimized || sidebarShow}/>
            <Box
              display={{ xs: "block" }}
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                zIndex: 0,
                background: theme.sidebar.background,
                height: 50,
                padding: 14,
                width: theme.sidebar.width,
              }}
              alignItems="center"
              textAlign={{ xs: "center" }}
            >
              <Box>
                <Typography variant="subtitle0">
                  &copy; {dayjs().format("YYYY")} - Park Limited
                </Typography>
              </Box>
            </Box>
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
